/* .mapboxgl-ctrl.mapboxgl-ctrl-group {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px;
  position: absolute;
  top: 50px;
  display: flex;
  right: 20px;
  flex-direction: column;
}

.mapbox-gl-draw_ctrl-draw-btn {
  background-color: white;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 2px;
}

.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash {} */

.cesium-viewer-bottom {
  display: none !important;
}

.cesium-viewer-fullscreenContainer {
  display: none !important;
}

.cesium-infoBox-defaultTable {
  border-collapse: separate;
  border-spacing: 0 1px; /* Adjust the second value to control the spacing between rows */
}

.cesium-infoBox-defaultTable tr:last-child {
  border-bottom: none;
}
